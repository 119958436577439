<template>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="OffersAccepteds">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ OffersAccepteds.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : OffersAccepteds.length }} of {{ OffersAccepteds.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage=4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <template slot="thead">
          <!-- <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("CustomerName")}}</vs-th> -->
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("ServiceProviderName")}}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("CustomerName")}}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("Price")}}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("Distance")}}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("Notes")}}</vs-th>
          <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("Status")}}</vs-th>
          <!-- <vs-th style="font-size: 16px;font-family:almarai ; color: crimson;">{{$t('Action')}}</vs-th> -->
        </template>
        <template slot-scope="{data}">
          <tbody>
            <tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <!-- <vs-td>
                <p class="product-name font-medium truncate">{{ tr.Request.Customer.NameAR }}</p>
              </vs-td> -->
              <vs-td>
                <p class="product-name font-medium truncate" v-if="tr.ServicesProvider != null">{{ tr.ServicesProvider.NameAR }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate" v-if="tr.ServiceRequest.Customer != null">{{ tr.ServiceRequest.Customer.NameAR }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.Price }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.Distance }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.Notes }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.Status }}</p>
              </vs-td>
              <!-- <vs-td class="whitespace-no-wrap">
              <feather-icon icon="InfoIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="showDetails(tr)" />
            </vs-td> -->
          </tr>
        </tbody>
        </template>
      </vs-table>
  
     
    </div>
  </template>
  
  <script>
import { domain } from "@/gloabelConstant.js";
import moduleOffersAccepted from "@/store/OffersAccepted/moduleOffersAccepted.js";

export default {
  data() {
    return {
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      popupActive: false,
      popupData: null,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    OffersAccepteds() {
      return this.$store.state.OffersAcceptedList.OffersAccepteds;
    },
  },
  methods: {
    showDetails(tr) {
      this.popupData = tr;
      this.popupActive = true;
    },
  },
  created() {
    if (!moduleOffersAccepted.isRegistered) {
      this.$store.registerModule("OffersAcceptedList", moduleOffersAccepted);
      moduleOffersAccepted.isRegistered = true;
    }
    this.$store.dispatch("OffersAcceptedList/fetchDataListItems");
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>


<style lang="scss">
#data-list-list-view {
.vs-con-table {
  .product-name {
    max-width: 23rem;
  }

  .vs-table--header {
    display: flex;
    flex-wrap: wrap-reverse;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    > span {
      display: flex;
      flex-grow: 1;
    }

    .vs-table--search {
      padding-top: 0;

      .vs-table--search-input {
        padding: 0.9rem 2.5rem;
        font-size: 1rem;

        & + i {
          left: 1rem;
        }

        &:focus + i {
          left: 1rem;
        }
      }
    }
  }

  .vs-table {
    border-collapse: separate;
    border-spacing: 0 1.3rem;
    padding: 0 1rem;

    tr {
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
      td {
        padding: 20px;
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
      td.td-check {
        padding: 20px !important;
      }
    }
  }

  .vs-table--thead {
    th {
      padding-top: 0;
      padding-bottom: 0;

      .vs-table-text {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    th.td-check {
      padding: 0 15px !important;
    }
    tr {
      background: none;
      box-shadow: none;
    }
  }

  .vs-table--pagination {
    justify-content: center;
  }
}
}
</style>
